<template>
  <div>
    <b-card no-body header-tag="header">
      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
        <strong>Eğitim ve tanıtım videoları</strong>
        <a class="pull-right" href="https://www.youtube.com/wisdomera" target="_blank">https://www.youtube.com/wisdomera</a>
      </b-card-header>
    </b-card>
    <template v-for="(video_group, video_group_ind) in d_videoList">
      <b-card no-body header-tag="header" style="background: black;">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
          <strong>{{ video_group.list_name }}</strong>
        </b-card-header>
        <br>
        <b-row style="margin: 0px;">
          <template v-for="(video, video_ind) in video_group.list">
            <b-col sm="12" md="4">
              <b-card no-body header-tag="header">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  <small>{{ video.name }}</small>
                </b-card-header>
                <iframe width="100%" :src="'https://www.youtube.com/embed/' + video.url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-card>
    </template>
  </div>
</template>

<script>
import PoleStarService from '@/services/polestar';
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';

export default {
  name: 'YoutubeVideo',
  components: {
    Modal
  },
  props: {
    p_activeLang: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      device: 'device'
    })
  },
  created: function () {
    // this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    this.f_prepareVideoList();
  },
  data () {
    return {
      d_videoList: []
    }
  },
  methods: {
    f_prepareVideoList: function () {
      let wisdomera_web_video = JSON.parse(localStorage.getItem('wisdomera_system')).web_video;
      this.d_videoList = [];
      for (let i in wisdomera_web_video) {
        let video_group = wisdomera_web_video[i];
        let push_data = {};
        if (video_group.lang_type === 'all' || (video_group.lang_type === 'selected') && video_group.selected_lang.indexOf(this.p_activeLang) !== 1) {
          push_data.list_name = video_group.list_name;
          push_data.list = [];
          for (let k in video_group.list) {
            let video = video_group.list[k];
            if (video.lang_type === 'all' || (video.lang_type === 'selected') && video.selected_lang.indexOf(this.p_activeLang) !== 1) {
              push_data.list.push({ 'url': video.url, 'source_type': video.source_type, 'name': video.name });
            }
          }
        }
        if (push_data.list && push_data.list.length > 0) {
          this.d_videoList.push(JSON.parse(JSON.stringify(push_data)));
        }
      }
      this.$forceUpdate();
    }
  },
  watch: {}
};

</script>

<style type="text/css">
.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

