<template>
  <div class="wrapper">
    <b-card bg-variant="dark">
      <youtube-video p_activeLang="tr"></youtube-video>
    </b-card>
  </div>
</template>

<script>
import {
  default as YoutubeVideo
} from '@/components/widgets/YoutubeVideo';
import { mapGetters } from 'vuex';
export default {
  name: 'Video',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    YoutubeVideo
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style>


</style>

